import { useTheme } from "@emotion/react";
import { Box, Checkbox } from "@mui/material";
import { GetPrices } from "components/gql/queris/GetPrices";
import { GetBasket } from "contexts/BasketContext";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import {
  getElementsPrices,
  getImageUrlByName,
  isIdentical,
  isObjectEmpty,
} from "data/functions";
import { FolderBlock } from "pages/services/FolderBlock";
import React, { useState } from "react";
import { ServiceBlock } from "./ServiceBlock";

export default function Services(props) {
  const { dataObj, setDataObj } = GetData();
  const theme = useTheme();
  const { cInfo } = GetCInfo();
  const { getPrice, inBasket, sectionItemAdd, sectionItemDelete, sections } =
    GetBasket();
  const [pricesQuery] = GetPrices();

  React.useEffect(() => {
    let arr = (dataObj?.serviceList ?? []).filter(
      (val) => val.parent_id === (props?.parent?.id || "") && !val?.folder,
    );
    if (!isObjectEmpty(arr) && cInfo?.UUID) {
      getElementsPrices(
        pricesQuery,
        arr,
        {
          dataObj,
          setDataObj,
        },
        { cInfo },
        { sectionItemAdd },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.parent, dataObj?.usedCar, dataObj?.serviceList]);

  function handleChecked(value) {
    let find = inBasket(value, sections.services);
    if (!find) sectionItemAdd("basket", value, sections.services);
    else sectionItemDelete("basket", value, sections.services);
  }
  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "0.4rem",
          display: "flex",
          // flexDirection: dataObj?.deviceWidth?.isMobile ? "column" : "row",
          flexDirection: "row",
          flexWrap: "wrap",
          width: dataObj?.deviceWidth?.isMobile ? "100%" : undefined,
          // justifyContent: "space-between",
          // width: dataObj?.deviceWidth?.isMobile
          //   ? "100%"
          //   : dataObj?.deviceWidth?.isPad
          //     ? "47%"
          //     : "30%",
          alignItems: dataObj?.deviceWidth?.isMobile ? undefined : "flex-start",
        }}
      >
        {(dataObj?.serviceList ?? [])
          .filter(
            (val) =>
              val.parent_id === (props?.parent?.id || "") && !val?.folder,
          )
          .map((value, index) => {
            let carID = dataObj?.usedCar?.car?.id;
            let priceObj = getPrice(value?.id, carID);
            let price = priceObj?.price || 0;
            let salePrice = priceObj?.salePrice || 0;
            let subPrice = priceObj?.priceType === "min_price" ? "от " : "";
            const labelId = `checkbox-list-label-${value}`;

            let check = inBasket(value, sections.services);
            return (
              <Box
                key={index}
                // onClick={() => {
                //   handleChecked(value);
                // }}
                sx={{
                  width: dataObj?.deviceWidth?.isMobile
                    ? "49%"
                    : dataObj?.deviceWidth?.isPad
                      ? "24%"
                      : "19%",
                }}
              >
                <ServiceBlock
                  description={value?.description}
                  iconElement={
                    <Checkbox
                      checked={check || false}
                      disableRipple
                      edge="start"
                      // color="info"

                      inputProps={{ "aria-labelledby": labelId }}
                      size="small"
                      sx={{
                        padding: "0.4rem",
                        margin: 0,
                        zIndex: 2,
                        "& .MuiSvgIcon-root": {
                          color: theme?.custom?.telegramColors?.button_color,
                        },
                      }}
                      tabIndex={-1}
                    />
                  }
                  image={getImageUrlByName(value.name)}
                  item={value}
                  price={
                    price ? (
                      price > salePrice ? (
                        <React.Fragment>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              flexWrap: "nowrap",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              {subPrice + salePrice + "₽ "}
                              <strike>{price}</strike>₽
                            </div>
                          </Box>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Box sx={{ textWrap: "nowrap" }}>
                            {subPrice + salePrice + "₽"}
                          </Box>
                        </React.Fragment>
                      )
                    ) : (
                      ""
                    )
                  }
                  setItem={handleChecked}
                  thereIsEl={true}
                  title={value.name}
                />
              </Box>
            );
          })}
      </Box>
    </React.Fragment>
  );
}
