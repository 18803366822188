import { ExpandMore, MiscellaneousServices } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import { GetData } from "contexts/DataContext";
import { getImageUrlByName, isObjectEmpty } from "data/functions";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const ServiceBlock = (props) => {
  const { dataObj } = GetData();
  const navigate = useNavigate();
  const ref = React.useRef(null);

  return (
    <Box
      sx={{
        // margin: "0.2rem",

        width: "100%",
        padding: props?.thereIsEl ? "2px 6px" : undefined,
        flex: "auto",
      }}
    >
      <Card
        sx={{
          position: "relative",
          borderLeft: props?.borderLeft ? props?.borderLeft : undefined,
          // border: props?.thereIsEl ? undefined : "1px solid black",

          display: props?.onBasket ? "flex" : undefined,
          alignItems: props?.onBasket ? "center" : undefined,
          flexDirection: props?.onBasket ? "row" : undefined,
          "@media (max-width: 310px)": {
            flexDirection: "column",
          },
          background: props?.folder ? "#ececec" : undefined,
        }}
      >
        <Box
          onClick={() => {
            if (props?.item) props?.setItem(props?.item);
          }}
          sx={{
            padding: "2px",
            background: props?.folder ? "#ececec" : undefined,
            position: "relative",
          }}
        >
          {/* {props?.image ? (
            <> */}
          {getImageUrlByName(props?.title) ? (
            <Box
              ref={ref}
              sx={{
                width: props?.onBasket ? "60px" : "100%",
                padding: "4px",
                height: props?.onBasket ? "60px" : undefined,
                aspectRatio: props?.onBasket ? undefined : "1 / 1",
                // maxHeight: "200px",

                background: `url(${getImageUrlByName(props?.title)}) center center / 140% no-repeat local padding-box padding-box rgb(255, 255, 255)`,
              }}
            />
          ) : (
            <Box sx={{ width: "100%" }}>
              <MiscellaneousServices
                sx={{
                  height: props?.onBasket ? "60px" : "100%",
                  width: props?.onBasket ? "60px" : "100%",
                  padding: "25%",
                  opacity: "0.1",
                }}
              />
            </Box>
          )}
          {/* </>
          ) : (
            <Box sx={{ height: "40px" }} />
          )} */}

          {!props?.onBasket && (
            <Box sx={{ position: "absolute", top: 0, left: 0, zIndex: 2 }}>
              {props.iconElement}
            </Box>
          )}
        </Box>
        <CardContent
          sx={{
            padding: "4px 12px !important",
            width: "100%",
            position: props?.folder ? "absolute" : undefined,
            bottom: props?.folder ? 0 : undefined,
            background: props?.folder ? "#ececec" : undefined,
            minHeight: props?.folder ? "40px" : undefined,
            display: props?.folder ? "flex" : undefined,
          }}
        >
          <Box
            onClick={() => {
              if (props?.item) props?.setItem(props?.item);
            }}
            sx={{
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "center",
              "@media (max-width: 310px)": {
                flexDirection: "column",
              },
              marginBottom: !props?.description ? 0 : undefined,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
              }}
            >
              {props?.onBasket && props?.iconElement}
              <Typography
                sx={{
                  fontWeight: props?.thereIsEl
                    ? "400 !important"
                    : "500 !important",
                  wordBreak: "break-word",
                  // fontWeight: "400",
                  color: props?.folder ? "black" : undefined,
                  lineHeight: "1.2 !important",
                  typography: {
                    sm: "body1",
                    xs: "body2",
                    md: "body2",
                    lg: "body1",
                  },
                }}
                // variant={
                //   props?.thereIsEl
                //     ? dataObj?.deviceWidth?.isMobile
                //       ? "body2"
                //       : "body1"
                //     : dataObj?.deviceWidth?.isMobile
                //       ? "body1"
                //       : "h6"
                // }
              >
                {props?.title}
              </Typography>
            </Box>
            {props?.onBasket && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ whiteSpace: "nowrap" }} variant="body2">
                  {props?.price}
                </Typography>
                {props?.deleteComponent}
              </Box>
            )}
          </Box>

          <Box
            onClick={() => {
              if (props?.item) props?.setItem(props?.item);
            }}
          >
            {props?.description && (
              <React.Fragment>
                <Box
                  // gutterBottom
                  sx={{
                    // display: "flex",
                    // flexDirection: "row",
                    // flexWrap: "nowrap",
                    // alignItems: "center",
                    fontSize: "90%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {props?.description}
                </Box>
                {/* <Typography
                  sx={{
                    width: "90%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: expanded ? undefined : "1",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {props?.description}
                </Typography> */}
              </React.Fragment>
            )}
          </Box>
          {
            // (dataObj.includeBeta || props?.fullDescription) &&
            props?.thereIsEl && !props?.onBasket && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",

                  padding: "4px",
                }}
              >
                {!props?.onBasket && !isObjectEmpty(props?.price) && (
                  <Box
                    onClick={() => {
                      if (props?.item) props?.setItem(props?.item);
                    }}
                    sx={{ fontWeight: "500" }}
                  >
                    {props?.price}
                  </Box>
                )}
                <Button
                  onClick={(e) =>
                    navigate(`/services/${props?.item?.id}`, {
                      state: { serviceID: props?.item?.id },
                    })
                  }
                  sx={{
                    lineHeight: "1.5",
                    textTransform: "none",
                    padding: 0,
                    zIndex: 1,
                    // width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  Подробнее
                </Button>
              </Box>
            )
          }
        </CardContent>
      </Card>
    </Box>
  );
};
