import { ExpandMore, MiscellaneousServices } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import { GetData } from "contexts/DataContext";
import { getImageUrlByName, isObjectEmpty } from "data/functions";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const FolderBlock = (props) => {
  const { dataObj } = GetData();
  const navigate = useNavigate();
  const ref = React.useRef(null);
  // const { width } = useContainerDimensions(ref);

  return (
    <Box
      onClick={() => {
        if (props?.item) props?.setItem(props?.item);
      }}
      sx={{
        width: "100%",
        flex: "auto",
      }}
    >
      <Card
        sx={{
          position: "relative",
          background: "#ececec",
        }}
      >
        <Box
          sx={{
            padding: "2px",
            background: "#ececec",
          }}
        >
          <Box
            ref={ref}
            sx={{
              width: "100%",
              padding: "4px",
              minHeight: "40px",
              aspectRatio: "1 / 1",
              height: getImageUrlByName(props?.title) ? undefined : "40px",
              // maxHeight: "200px",

              background: getImageUrlByName(props?.title)
                ? `url(${getImageUrlByName(props?.title)}) center 36% / 140% no-repeat local padding-box padding-box rgb(255, 255, 255)`
                : undefined,
            }}
          />
        </Box>
        <CardContent
          sx={{
            padding: "4px 12px !important",
            width: "100%",
            position: "absolute",
            bottom: 0,
            background: "#ececec",
            minHeight: "40px",
            display: "flex",
          }}
        >
          <Box
            sx={{
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "center",
              marginBottom: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
              }}
            >
              {props?.iconElement}
              <Typography
                sx={{
                  fontWeight: "500 !important",
                  wordBreak: "break-word",
                  // fontWeight: "400",
                  color: "black",
                  lineHeight: "1.2 !important",
                  typography: {
                    sm: "body1",
                    xs: "body2",
                    md: "body2",
                    lg: "body1",
                  },
                }}
              >
                {props?.title}
              </Typography>
            </Box>
          </Box>
          {!isObjectEmpty(props?.price) && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                fontSize: "100%",
                fontWeight: "500",
              }}
            >
              {"Цена: "} {props?.price}
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};
