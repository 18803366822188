import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { GetData } from "contexts/DataContext";
import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Mousewheel, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { MiscellaneousServices } from "@mui/icons-material";
import {
  assignedArray,
  changeInArray,
  getImageUrlByName,
  isIdentical,
  isObjectEmpty,
} from "data/functions";
import { useNavigate } from "react-router-dom";

export const ServiceSlider = () => {
  const { dataObj, setDataObj } = GetData();
  const [serviceArray, setServiceArray] = useState(dataObj?.serviceList || []);

  const [activeSlide, setActiveSlide] = useState();

  useEffect(() => {}, [activeSlide]);
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <React.Fragment>
      <Box sx={{ backgroundColor: "transparent" }}>
        {!isObjectEmpty(serviceArray) && (
          <Swiper
            initialSlide={activeSlide}
            injectStyles={[
              ":host .swiper-wrapper { align-items: center !important; }",
            ]}
            loop={serviceArray.length > 1 ? true : false}
            modules={[Pagination, Navigation, Mousewheel]}
            mousewheel={{
              releaseOnEdges: true,
            }}
            pagination={{ clickable: true, dynamicBullets: true }}
            slidesPerView={"auto"}
            spaceBetween={14}
            style={{
              padding: "0.4rem 36px 1.2rem 14px",
              width: "100%",
              background: "transparent",
              "--swiper-navigation-size": "14px",
              "--swiper-pagination-color":
                theme?.custom?.telegramColors?.button_color,
              "--swiper-pagination-bullet-inactive-color":
                theme?.custom?.telegramColors?.button_color,
              "--swiper-pagination-bullet-inactive-opacity": 0.8,
              // boxShadow: "rgb(91 72 72 / 60%) 0px 1px 2px 0px",
              // borderRadius: "4px",
              display: "flex",
            }}
          >
            {[...(serviceArray ?? [])]
              .filter((el) => el.folder === false)
              ?.map((element, index) => (
                <SwiperSlide
                  key={index}
                  onClick={(e) =>
                    navigate(`/services/${element?.id}`, {
                      state: { id: element?.id },
                    })
                  }
                  style={{
                    maxWidth: dataObj?.deviceWidth?.isMobile
                      ? "254px"
                      : "400px",
                    height: "100%",
                  }}
                >
                  <Card
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {getImageUrlByName(element?.name) ? (
                      <Box
                        sx={{
                          padding: "4px",
                          height: dataObj?.deviceWidth?.isMobile
                            ? "40px"
                            : "80px",
                          width: dataObj?.deviceWidth?.isMobile
                            ? "60px"
                            : "260px",
                          background: `url(${getImageUrlByName(element?.name)}) center center / cover no-repeat local padding-box padding-box rgb(255, 255, 255)`,
                        }}
                      >
                        {/* <img
                        alt={element?.name}
                        src={getImageUrlByName(element?.name)}
                        style={{ width: "100%" }}
                      /> */}
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          height: "40px",
                          width: "60px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <MiscellaneousServices
                          sx={{
                            width: "30px",
                            height: "40px",
                            opacity: "0.2",
                          }}
                        />
                      </Box>
                    )}
                    <CardContent
                      sx={{
                        padding: "4px 4px 4px 12px!important",
                        width: "100%",
                      }}
                    >
                      <Tooltip title={element?.name}>
                        <Typography
                          sx={{
                            fontSize: dataObj?.deviceWidth?.isMobile
                              ? "0.87rem"
                              : undefined,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {element?.name}
                        </Typography>
                      </Tooltip>
                    </CardContent>
                  </Card>
                </SwiperSlide>
              ))}
          </Swiper>
        )}
      </Box>
    </React.Fragment>
  );
};
